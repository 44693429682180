<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="302"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="`${this.logo}`"
          max-height="auto"
          max-width="200px"
          alt="logo"
          contain
          eager
          class="app-logo ml-5"
        ></v-img>
        <v-slide-x-transition v-if="this.logo === ''">
          <h2 class="app-title text--primary">
            {{ imobiliaria }}
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <nav-menu-section-title title="OPÇÕES"></nav-menu-section-title>
    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Inicio"
        :to="{ name: 'portal' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="2ª via Boletos"
        :to="{ name: 'resumo-boletos'}"
        :icon="icons.mdiBarcode"
      ></nav-menu-link>
      <!-- <nav-menu-link
        title="Atendimento"
        :to="{ name: 'resumo-atendimentos'}"
        :icon="icons.mdiTicket"
      ></nav-menu-link> -->
      <nav-menu-link
        title="Extrato de Pagamentos"
        :to="{name: 'resumo-pagamentos'}"
        :icon="icons.mdiFinance"
      ></nav-menu-link>
      <nav-menu-link
        title="Imoveis"
        :to="{ name: 'resumo-empreendimentos'}"
        :icon="icons.mdiHomeGroup"
      ></nav-menu-link>
      <nav-menu-link
        title="Imposto de Renda"
        :to="{ name: 'resumo-extrato'}"
        :icon="icons.mdiCashRegister"
      ></nav-menu-link>

      <nav-menu-section-title title="EXTRAS"
      v-if="modules.acompanhamento_obra === `true` || modules.module_anunciar_unidade === `true`"></nav-menu-section-title>
      <nav-menu-link
        v-if="modules.acompanhamento_obra === `true`"
        title="Acomp. Obra" 
        :to="{ name: 'obras-acompanhamento'}"
        :icon="icons.mdiAccountHardHat"
      ></nav-menu-link>

      <nav-menu-link
        title="Documentos" 
        :to="{ name: 'documentos'}"
        :icon="icons.mdiFolderOutline"
      ></nav-menu-link>

      <nav-menu-link
        v-if="modules.module_anunciar_unidade === `true`"
        title="Unidades à venda"
        :to="{ name: 'anuncios'}"
        :icon="icons.mdiSale"
      ></nav-menu-link>

      <nav-menu-section-title title="DEFINIÇÕES DO USUÁRIO"></nav-menu-section-title>
      <nav-menu-link
        title="Meus Dados"
        :to="{ name: 'meus-dados'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <nav-menu-section-title title="CONTATO"></nav-menu-section-title>
      <nav-menu-link
        title="Atendimento"
        :to="{ name: 'resumo-atendimentos'}"
        :icon="icons.mdiFaceAgent"
      ></nav-menu-link>
      <nav-menu-link
        title="Reportar bug"
        :to="{ name: 'reportar-bug'}"
        :icon="icons.mdiBug"
      ></nav-menu-link>
      <!--
      <nav-menu-section-title title="UTILIDADES"></nav-menu-section-title>
      <nav-menu-link
        title="FAQ's"
        :icon="icons.mdiFrequentlyAskedQuestions"
      ></nav-menu-link>
      -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiBug,
  mdiRoomService,
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiBarcode,
  mdiTicket,
  mdiOfficeBuilding,
  mdiCashRegister,
  mdiFaceAgent,
  mdiWallet,
  mdiFrequentlyAskedQuestions,
  mdiFinance,
  mdiAccountHardHat,
  mdiSale,
  mdiHomeGroup,
  mdiFolderOutline
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import Strings from '@/common/strings'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data(){
    return{
      imobiliaria: process.env.VUE_APP_INCORPORATION_NAME,
      logo: process.env.VUE_APP_URL_LOGO,
      modules: {
        acompanhamento_obra: Strings.module_acompanhamento_obra,
        module_anunciar_unidade: Strings.module_anunciar_unidade,
      }
    }
  },  
  setup() {
    return {
      icons: {
        mdiBug,
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiBarcode,
        mdiTicket,
        mdiOfficeBuilding,
        mdiCashRegister,
        mdiFaceAgent,
        mdiRoomService,
        mdiWallet,
        mdiFrequentlyAskedQuestions,
        mdiFinance,
        mdiAccountHardHat,
        mdiSale,
        mdiHomeGroup,
        mdiFolderOutline
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
